<template>
  <div v-loading="loadingDetail.list" class="partition-area">
    <ics-page-inner title="企业资料补充" :show-header="true">
      <div v-for="(item, index) in companyData.tableList" :key="index" class="tabs-inner" style="margin-top: 10px;">
        <table-inner :title="item.typeName" :table-data="item.dictInfos" :table-footer="false" show-header="false">
          <template slot="table-bd">
            <el-table
              :stripe="false"
              :show-header="false"
              :data="item.dictInfos"
            >
              <el-table-column
                prop="name"
                :formatter="utils.isEffective"
                show-overflow-tooltip
                min-width="200"
              />
              <el-table-column
                :formatter="utils.isEffective"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  <div v-if="agencyView()">
                    <template v-if="JSON.stringify(scope.row.ext) !== '{}'">
                      <!-- <a download :href="_.get(scope.row, 'fileDetailInfoVo.fileUrl')">{{ _.get(scope.row, 'fileDetailInfoVo.fileName', '-') }}</a> -->
                      <a href="javascript:" class="text-btn" @click="utils.downloadP('firm', scope.row.ext.url)">{{ scope.row.ext.name }}</a>
                      <a class="danger ml5" href="javascript:" @click="delFileUrl(index, scope.$index)"><i class="el-icon-delete" /></a>
                    </template>
                    <a v-else class="text-btn" href="javascript:" @click="uploadFile(scope.row, index, scope.$index)">上传材料</a>
                  </div>
                  <div v-if="!agencyView()">
                    <template v-if="JSON.stringify(scope.row.ext) !== '{}'">
                      <a href="javascript:" class="text-btn" @click="utils.downloadP('firm', scope.row.ext.url)">{{ scope.row.ext.name }}</a>
                    </template>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </table-inner>
      </div>
      <ics-button-inner v-if="agencyView()" :loading="loadingDetail.submit" submit-title="提交" cancel-title="返回" @submit="submitForms" @cancel="retreat" />
    </ics-page-inner>
    <upload-file-dialog ref="uploadFile" :firm-id="firmInfo.firmId" :firm-name="firmInfo.firmName" :type-code="this.editFile.code" :type-name="this.editFile.name" @onSuccessFile="onSuccessFile" />
  </div>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import UploadFileDialog from '@/components/template/upload-file-dialog'
export default {
  name: 'IcsCompanyDataInner',
  components: { UploadFileDialog },
  mixins: [routeEnterMixin],
  props: {
    loadingDetail: {
      type: Object,
      default () {
        return {}
      }
    },
    companyData: {
      type: Object,
      default () {
        return {}
      }
    },
    firmInfo: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      editFile: {}
    }
  },
  created () {
    if (this.agencyView()) {
    }
  },
  methods: {
    agencyView () {
      if (this.$route.params.editMode === 'agency') {
        return true
      } else {
        return false
      }
    },
    uploadFile (row, previousStepIndex, index) {
      this.editFile = this._.cloneDeep(row)
      this.editFile.previousStepIndex = previousStepIndex
      this.editFile.index = index
      this.$refs.uploadFile.dialog.update = true
    },
    onSuccessFile (res) {
      const data = this._.cloneDeep(this.editFile)
      data.ext = res.data
      this.qualificationUpdate(data)
    },
    qualificationUpdate (data) {
      const params = this.companyData.tableList[this.editFile.previousStepIndex]
      params.dictInfos[this.editFile.index].ext = data.ext
      this.$set(this.companyData.tableList[this.editFile.previousStepIndex].dictInfos[this.editFile.index], this.editFile.index, data)
      console.log(this.companyData)
      this.editFile = {}
    },
    openFile (row) {
      this.utils.downloadP('firm', row.ext.url)
    },
    delFileUrl (previousStepIndex, index) {
      this.companyData.tableList[previousStepIndex].dictInfos[index].ext = {}
    },
    submitForms () {
      this.$emit('submit')
    },
    retreat () {
      this.$emit('retreat')
    }
  }
}
</script>

<style scoped>

</style>
