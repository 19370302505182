var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingDetail.detail,
              expression: "loadingDetail.detail"
            }
          ],
          ref: "appForm",
          attrs: {
            model: _vm.appForm,
            rules: _vm.rules,
            "label-width": "170px",
            "label-suffix": _vm.constants.labelSuffix
          }
        },
        [
          _c(
            "ics-page-inner",
            { attrs: { title: "企业基本资料" } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "统一社会信用代码" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(_vm.appForm.socialNo)
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "企业全称" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(_vm.appForm.companyName)
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "companyShortName", label: "企业简称" } },
                    [
                      _c(
                        "ics-item-inner",
                        {
                          attrs: {
                            prop: _vm.appForm.companyShortName,
                            format: _vm.utils.isEffectiveCommon
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入企业简称" },
                            model: {
                              value: _vm.appForm.companyShortName,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "companyShortName", $$v)
                              },
                              expression: "appForm.companyShortName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "regAmount", label: "注册资本(元)" } },
                    [
                      _c(
                        "ics-item-inner",
                        {
                          attrs: {
                            prop: _vm.appForm.regAmount,
                            format: _vm.utils.isEffectiveCommon
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              placeholder: "请输入注册资本"
                            },
                            model: {
                              value: _vm.appForm.regAmount,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "regAmount", $$v)
                              },
                              expression: "appForm.regAmount"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "establishDate", label: "成立日期" } },
                    [
                      _c(
                        "ics-item-inner",
                        {
                          attrs: {
                            prop: _vm.appForm.establishDate,
                            format: function(val) {
                              return _vm.utils.dateFormat(val)
                            }
                          }
                        },
                        [
                          _c("ics-search-date", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              date: _vm.appForm.establishDate,
                              type: "date"
                            },
                            on: {
                              "update:date": function($event) {
                                return _vm.$set(
                                  _vm.appForm,
                                  "establishDate",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "contactPhone", label: "企业联系电话" } },
                    [
                      _c(
                        "ics-item-inner",
                        {
                          attrs: {
                            prop: _vm.appForm.contactPhone,
                            format: _vm.utils.isEffectiveCommon
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入企业联系电话" },
                            model: {
                              value: _vm.appForm.contactPhone,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "contactPhone", $$v)
                              },
                              expression: "appForm.contactPhone"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.agencyView()
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "usEnterpriseAddress",
                            label: "注册地址"
                          }
                        },
                        [
                          _c(
                            "ics-item-inner",
                            {
                              attrs: {
                                prop: _vm.appForm.usEnterpriseAddress,
                                format: _vm.utils.isEffectiveCommon
                              }
                            },
                            [
                              _c("address-picker", {
                                attrs: {
                                  "is-show-address-info": _vm.isShowAddressInfo
                                },
                                model: {
                                  value: _vm.appForm.usEnterpriseAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.appForm,
                                      "usEnterpriseAddress",
                                      $$v
                                    )
                                  },
                                  expression: "appForm.usEnterpriseAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.agencyView()
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "注册地址" } },
                        [
                          _c("address-picker-detail", {
                            model: {
                              value: _vm.appForm.usEnterpriseAddress,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.appForm,
                                  "usEnterpriseAddress",
                                  $$v
                                )
                              },
                              expression: "appForm.usEnterpriseAddress"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.agencyView()
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "dsEnterpriseAddress",
                            label: "办公地址"
                          }
                        },
                        [
                          _c(
                            "ics-item-inner",
                            {
                              attrs: {
                                prop: _vm.appForm.dsEnterpriseAddress,
                                format: _vm.utils.isEffectiveCommon
                              }
                            },
                            [
                              _c("address-picker", {
                                attrs: {
                                  "is-show-address-info": _vm.isShowAddressInfo
                                },
                                model: {
                                  value: _vm.appForm.dsEnterpriseAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.appForm,
                                      "dsEnterpriseAddress",
                                      $$v
                                    )
                                  },
                                  expression: "appForm.dsEnterpriseAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.agencyView()
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "办公地址" } },
                        [
                          _c("address-picker-detail", {
                            model: {
                              value: _vm.appForm.dsEnterpriseAddress,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.appForm,
                                  "dsEnterpriseAddress",
                                  $$v
                                )
                              },
                              expression: "appForm.dsEnterpriseAddress"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.agencyView()
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "营业执照照片" } },
                        [
                          _c("el-image", {
                            staticClass: "avatar",
                            attrs: {
                              src: _vm.appForm.licenseUrl,
                              "preview-src-list": [_vm.appForm.licenseUrl]
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.agencyView()
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "营业执照照片", required: "" } },
                        [
                          _c(
                            "div",
                            { staticStyle: { width: "55%", display: "table" } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "25%",
                                    float: "left",
                                    "margin-right": "120px",
                                    display: "table-cell"
                                  }
                                },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: { width: "120px" },
                                          attrs: { span: 12 }
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "",
                                                prop: "licenseUrl"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-upload",
                                                {
                                                  staticClass:
                                                    "avatar-uploader",
                                                  attrs: {
                                                    action:
                                                      _vm.licenseFileUploadUrl,
                                                    "show-file-list": false,
                                                    "on-success":
                                                      _vm.licenseUrlSuccess,
                                                    "on-error": _vm.onError,
                                                    "before-upload":
                                                      _vm.licenseUrlUpload
                                                  }
                                                },
                                                [
                                                  _vm.appForm.licenseUrl
                                                    ? _c("img", {
                                                        staticClass: "avatar",
                                                        attrs: {
                                                          src:
                                                            _vm.appForm
                                                              .licenseUrl
                                                        }
                                                      })
                                                    : _c("i", {
                                                        staticClass:
                                                          "el-icon-plus avatar-uploader-icon"
                                                      }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "el-upload__tip",
                                                      staticStyle: {
                                                        width: "100px"
                                                      },
                                                      attrs: { slot: "tip" },
                                                      slot: "tip"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " 上传营业执照照片 "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "ics-page-inner",
            { attrs: { title: "企业法定代表人信息" } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "legalPerson", label: "法定代表人姓名" } },
                    [
                      _c(
                        "ics-item-inner",
                        {
                          attrs: {
                            prop: _vm.appForm.legalPerson,
                            format: _vm.utils.isEffectiveCommon
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入法定代表人姓名" },
                            model: {
                              value: _vm.appForm.legalPerson,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "legalPerson", $$v)
                              },
                              expression: "appForm.legalPerson"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "contactPhone",
                        label: "法定代表人联系电话"
                      }
                    },
                    [
                      _c(
                        "ics-item-inner",
                        {
                          attrs: {
                            prop: _vm.appForm.contactPhone,
                            format: _vm.utils.isEffectiveCommon
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入法定代表人联系电话" },
                            model: {
                              value: _vm.appForm.contactPhone,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "contactPhone", $$v)
                              },
                              expression: "appForm.contactPhone"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "documentType", label: "证件类型" } },
                    [
                      _c(
                        "ics-item-inner",
                        {
                          attrs: {
                            prop: _vm.appForm.documentType,
                            format: function(val) {
                              return _vm.utils.statusFormat(
                                Number(val),
                                "documentType"
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择证件类型",
                                filterable: ""
                              },
                              model: {
                                value: _vm.appForm.documentType,
                                callback: function($$v) {
                                  _vm.$set(_vm.appForm, "documentType", $$v)
                                },
                                expression: "appForm.documentType"
                              }
                            },
                            _vm._l(_vm.constants.documentType, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "legalIdentity", label: "证件号码" } },
                    [
                      _c(
                        "ics-item-inner",
                        {
                          attrs: {
                            prop: _vm.appForm.legalIdentity,
                            format: _vm.utils.isEffectiveCommon
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入证件号码" },
                            model: {
                              value: _vm.appForm.legalIdentity,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "legalIdentity", $$v)
                              },
                              expression: "appForm.legalIdentity"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "email", label: "电子邮箱" } },
                    [
                      _c(
                        "ics-item-inner",
                        {
                          attrs: {
                            prop: _vm.appForm.email,
                            format: _vm.utils.isEffectiveCommon
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入电子邮箱" },
                            model: {
                              value: _vm.appForm.email,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "email", $$v)
                              },
                              expression: "appForm.email"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("el-col"),
              !_vm.agencyView()
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "法定代表人身份证照片" } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 7 } },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("el-image", {
                                        staticClass: "avatar",
                                        attrs: {
                                          src: _vm.appForm.idCardUrlZ,
                                          "preview-src-list": [
                                            _vm.appForm.idCardUrlZ,
                                            _vm.appForm.idCardUrlF
                                          ]
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 7, pull: 2 } },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("el-image", {
                                        staticClass: "avatar",
                                        attrs: {
                                          src: _vm.appForm.idCardUrlF,
                                          "preview-src-list": [
                                            _vm.appForm.idCardUrlF,
                                            _vm.appForm.idCardUrlZ
                                          ]
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.agencyView()
                ? _c(
                    "el-col",
                    { attrs: { span: 14 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "法定代表人身份证照片", required: "" }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { width: "55%", display: "table" } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "25%",
                                    float: "left",
                                    "margin-right": "120px",
                                    display: "table-cell"
                                  }
                                },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: { width: "120px" },
                                          attrs: { span: 12 }
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "idCardUrlZ" } },
                                            [
                                              _c(
                                                "el-upload",
                                                {
                                                  staticClass:
                                                    "avatar-uploader",
                                                  attrs: {
                                                    action:
                                                      _vm.idCardFileZUploadUrl,
                                                    "show-file-list": false,
                                                    "on-success":
                                                      _vm.idCardUrlZSuccess,
                                                    "on-error": _vm.onError,
                                                    "before-upload":
                                                      _vm.idCardUrlZUpload
                                                  }
                                                },
                                                [
                                                  _vm.appForm.idCardUrlZ
                                                    ? _c("img", {
                                                        staticClass: "avatar",
                                                        attrs: {
                                                          src:
                                                            _vm.appForm
                                                              .idCardUrlZ
                                                        }
                                                      })
                                                    : _c("i", {
                                                        staticClass:
                                                          "el-icon-plus avatar-uploader-icon"
                                                      }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "el-upload__tip",
                                                      attrs: { slot: "tip" },
                                                      slot: "tip"
                                                    },
                                                    [_vm._v(" 上传身份证正面 ")]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "25%",
                                    float: "left",
                                    "margin-right": "10px",
                                    display: "table-cell"
                                  }
                                },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: { width: "120px" },
                                          attrs: { span: 12 }
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "idCardUrlF" } },
                                            [
                                              _c(
                                                "el-upload",
                                                {
                                                  staticClass:
                                                    "avatar-uploader",
                                                  attrs: {
                                                    action:
                                                      _vm.idCardFileFUploadUrl,
                                                    "show-file-list": false,
                                                    "on-success":
                                                      _vm.idCardUrlFSuccess,
                                                    "on-error": _vm.onError,
                                                    "before-upload":
                                                      _vm.idCardUrlFUpload
                                                  }
                                                },
                                                [
                                                  _vm.appForm.idCardUrlF
                                                    ? _c("img", {
                                                        staticClass: "avatar",
                                                        attrs: {
                                                          src:
                                                            _vm.appForm
                                                              .idCardUrlF
                                                        }
                                                      })
                                                    : _c("i", {
                                                        staticClass:
                                                          "el-icon-plus avatar-uploader-icon"
                                                      }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "el-upload__tip",
                                                      staticStyle: {
                                                        width: "100px"
                                                      },
                                                      attrs: { slot: "tip" },
                                                      slot: "tip"
                                                    },
                                                    [_vm._v(" 上传身份证反面 ")]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.agencyView()
                ? _c("ics-button-inner", {
                    attrs: {
                      loading: _vm.loadingDetail.submit,
                      "submit-title": "下一步",
                      "cancel-title": "返回"
                    },
                    on: { submit: _vm.submitForms }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("ics-check-dialog-inner", {
        attrs: { dialog: _vm.checkDialog, "check-info": _vm.checkInfo },
        on: {
          "update:dialog": function($event) {
            _vm.checkDialog = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }