<template>
  <el-main>
    <ics-dialog-inner :loading="loading.detail" :visible.sync="dialog.licenseUrl.visible" :title="dialog.licenseUrl.title" cancel-title="确认" :show-submit="false" class="form-dialog" width="60%">
      <el-form label-width="140px" label-position="left" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="" :show-header="true">
          <el-col :span="12">
            <el-form-item label="统一社会信用代码">
              <p>{{ utils.isEffectiveCommon(checkInfo.uniformSocialCreditCode) }}</p>
            </el-form-item>
          </el-col>
          <el-col />
          <el-col :span="12">
            <el-form-item label="公司名称">
              <p>{{ utils.isEffectiveCommon(checkInfo.companyName) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="注册资本">
              <p>{{ utils.isEffectiveCommon(checkInfo.registeredCapital) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公司类型">
              <p>{{ utils.isEffectiveCommon(checkInfo.companyType) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="成立日期">
              <p>{{ utils.isEffectiveCommon(checkInfo.startTime) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="法定代表人姓名">
              <p>{{ utils.isEffectiveCommon(checkInfo.startTime) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="营业期限">
              <p>{{ utils.isEffectiveCommon(checkInfo.operatingPeriod) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="经营范围">
              <p>{{ utils.isEffectiveCommon(checkInfo.scope) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="住所">
              <p>{{ utils.isEffectiveCommon(checkInfo.address) }}</p>
            </el-form-item>
          </el-col>
        </ics-page-inner>
      </el-form>
    </ics-dialog-inner>
    <ics-dialog-inner :loading="loading.detail" :visible.sync="dialog.idCardUrlZ.visible" :title="dialog.idCardUrlZ.title" cancel-title="确认" :show-submit="false" class="form-dialog" width="40%">
      <el-form label-width="140px" label-position="left" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="" :show-header="true">
          <el-col :span="12">
            <el-form-item label="姓名">
              <p>{{ utils.isEffectiveCommon(checkInfo.name) }}</p>
            </el-form-item>
          </el-col>
          <el-col />
          <el-col :span="12">
            <el-form-item label="性别">
              <p>{{ utils.isEffectiveCommon(checkInfo.gender) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="民族">
              <p>{{ utils.isEffectiveCommon(checkInfo.nationality) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="出生日期">
              <p>{{ utils.isEffectiveCommon(checkInfo.birthday) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="住址">
              <p>{{ utils.isEffectiveCommon(checkInfo.address) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="身份证号码">
              <p>{{ utils.isEffectiveCommon(checkInfo.identificationNumber) }}</p>
            </el-form-item>
          </el-col>
        </ics-page-inner>
      </el-form>
    </ics-dialog-inner>
    <ics-dialog-inner :loading="loading.detail" :visible.sync="dialog.idCardUrlF.visible" :title="dialog.idCardUrlF.title" cancel-title="确认" :show-submit="false" class="form-dialog" width="35%">
      <el-form label-width="140px" label-position="left" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="" :show-header="true">
          <el-row>
            <el-col :span="12" push="6">
              <el-form-item label="签发机关">
                <p>{{ utils.isEffectiveCommon(checkInfo.issueAuthority) }}</p>
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="12" push="6">
              <el-form-item label="有效期限">
                <p>{{ utils.isEffectiveCommon(checkInfo.validPeriod) }}</p>
              </el-form-item>
            </el-col>
          </el-row>
        </ics-page-inner>
      </el-form>
    </ics-dialog-inner>
  </el-main>
</template>

<script>
import IcsDialogInner from '@/components/dialog-inner'
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsPageInner from "../../../components/page-inner";
export default {
  name: 'IcsCheckDialogInner',
  components: {IcsPageInner, IcsDialogInner },
  mixins: [routeEnterMixin],
  props: {
    dialog: {
      type: Object,
      default() {
        return {}
      }
    },
    checkInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    checkType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  }
}
</script>

<style scoped>

</style>
