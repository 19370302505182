var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingDetail.list,
          expression: "loadingDetail.list"
        }
      ],
      staticClass: "partition-area"
    },
    [
      _c(
        "ics-page-inner",
        { attrs: { title: "企业资料补充", "show-header": true } },
        [
          _vm._l(_vm.companyData.tableList, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "tabs-inner",
                staticStyle: { "margin-top": "10px" }
              },
              [
                _c(
                  "table-inner",
                  {
                    attrs: {
                      title: item.typeName,
                      "table-data": item.dictInfos,
                      "table-footer": false,
                      "show-header": "false"
                    }
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-bd" },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              stripe: false,
                              "show-header": false,
                              data: item.dictInfos
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                formatter: _vm.utils.isEffective,
                                "show-overflow-tooltip": "",
                                "min-width": "200"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                formatter: _vm.utils.isEffective,
                                "show-overflow-tooltip": "",
                                "min-width": "100"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm.agencyView()
                                          ? _c(
                                              "div",
                                              [
                                                JSON.stringify(
                                                  scope.row.ext
                                                ) !== "{}"
                                                  ? [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "text-btn",
                                                          attrs: {
                                                            href: "javascript:"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.utils.downloadP(
                                                                "firm",
                                                                scope.row.ext
                                                                  .url
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row.ext.name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "danger ml5",
                                                          attrs: {
                                                            href: "javascript:"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.delFileUrl(
                                                                index,
                                                                scope.$index
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-delete"
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  : _c(
                                                      "a",
                                                      {
                                                        staticClass: "text-btn",
                                                        attrs: {
                                                          href: "javascript:"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.uploadFile(
                                                              scope.row,
                                                              index,
                                                              scope.$index
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("上传材料")]
                                                    )
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        !_vm.agencyView()
                                          ? _c(
                                              "div",
                                              [
                                                JSON.stringify(
                                                  scope.row.ext
                                                ) !== "{}"
                                                  ? [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "text-btn",
                                                          attrs: {
                                                            href: "javascript:"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.utils.downloadP(
                                                                "firm",
                                                                scope.row.ext
                                                                  .url
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row.ext.name
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            )
          }),
          _vm.agencyView()
            ? _c("ics-button-inner", {
                attrs: {
                  loading: _vm.loadingDetail.submit,
                  "submit-title": "提交",
                  "cancel-title": "返回"
                },
                on: { submit: _vm.submitForms, cancel: _vm.retreat }
              })
            : _vm._e()
        ],
        2
      ),
      _c("upload-file-dialog", {
        ref: "uploadFile",
        attrs: {
          "firm-id": _vm.firmInfo.firmId,
          "firm-name": _vm.firmInfo.firmName,
          "type-code": this.editFile.code,
          "type-name": this.editFile.name
        },
        on: { onSuccessFile: _vm.onSuccessFile }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }