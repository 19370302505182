var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c(
        "ics-dialog-inner",
        {
          staticClass: "form-dialog",
          attrs: {
            loading: _vm.loading.detail,
            visible: _vm.dialog.licenseUrl.visible,
            title: _vm.dialog.licenseUrl.title,
            "cancel-title": "确认",
            "show-submit": false,
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialog.licenseUrl, "visible", $event)
            }
          }
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-width": "140px",
                "label-position": "left",
                "label-suffix": _vm.constants.labelSuffix
              }
            },
            [
              _c(
                "ics-page-inner",
                { attrs: { title: "", "show-header": true } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "统一社会信用代码" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.isEffectiveCommon(
                                  _vm.checkInfo.uniformSocialCreditCode
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c("el-col"),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "公司名称" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.checkInfo.companyName
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "注册资本" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.checkInfo.registeredCapital
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "公司类型" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.checkInfo.companyType
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "成立日期" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.checkInfo.startTime
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "法定代表人姓名" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.isEffectiveCommon(
                                  _vm.checkInfo.startTime
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "营业期限" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.checkInfo.operatingPeriod
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "经营范围" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(_vm.checkInfo.scope)
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "住所" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(_vm.checkInfo.address)
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "ics-dialog-inner",
        {
          staticClass: "form-dialog",
          attrs: {
            loading: _vm.loading.detail,
            visible: _vm.dialog.idCardUrlZ.visible,
            title: _vm.dialog.idCardUrlZ.title,
            "cancel-title": "确认",
            "show-submit": false,
            width: "40%"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialog.idCardUrlZ, "visible", $event)
            }
          }
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-width": "140px",
                "label-position": "left",
                "label-suffix": _vm.constants.labelSuffix
              }
            },
            [
              _c(
                "ics-page-inner",
                { attrs: { title: "", "show-header": true } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "姓名" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(_vm.checkInfo.name)
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("el-col"),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "性别" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(_vm.checkInfo.gender)
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "民族" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.checkInfo.nationality
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "出生日期" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.checkInfo.birthday
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "住址" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(_vm.checkInfo.address)
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "身份证号码" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.isEffectiveCommon(
                                _vm.checkInfo.identificationNumber
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "ics-dialog-inner",
        {
          staticClass: "form-dialog",
          attrs: {
            loading: _vm.loading.detail,
            visible: _vm.dialog.idCardUrlF.visible,
            title: _vm.dialog.idCardUrlF.title,
            "cancel-title": "确认",
            "show-submit": false,
            width: "35%"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialog.idCardUrlF, "visible", $event)
            }
          }
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-width": "140px",
                "label-position": "left",
                "label-suffix": _vm.constants.labelSuffix
              }
            },
            [
              _c(
                "ics-page-inner",
                { attrs: { title: "", "show-header": true } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12, push: "6" } },
                        [
                          _c("el-form-item", { attrs: { label: "签发机关" } }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.isEffectiveCommon(
                                    _vm.checkInfo.issueAuthority
                                  )
                                )
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _c("el-col"),
                      _c(
                        "el-col",
                        { attrs: { span: 12, push: "6" } },
                        [
                          _c("el-form-item", { attrs: { label: "有效期限" } }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.isEffectiveCommon(
                                    _vm.checkInfo.validPeriod
                                  )
                                )
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }